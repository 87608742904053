import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Icon`}</h1>
    <p>{`Icons are visual symbols for communicating additional
meaning, status, and available actions.`}</p>
    <ComponentPreview allowScrolling componentName="icons--gallery" maxWidth="100%" maxHeight="1000px" hasReact hasAngular hasHTML titleAttr="Icon Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Icons can be used to provide meaning and bring visual
interest to elements of a page. They can also be used to
indicate available actions and to clarify status or
give feedback.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Icons should only be sized to `}<inlineCode parentName="li">{`16px`}</inlineCode>{`, `}<inlineCode parentName="li">{`24px`}</inlineCode>{`, or `}<inlineCode parentName="li">{`32px`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Icons should not be used in place of artwork or illustrations.`}</li>
      <li parentName="ul">{`If used as a clickable interaction with a text label,
use first-person pronouns. For example: Use "Download My
Approval Letter," instead of "Download Your Approval Letter."`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Accessibility`}</h2>
    <p>{`Icons are a great way to provide additional meaning for clients
with cognitive and visual disabilities. They should be
accompanied by text whenever possible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      